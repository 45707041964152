import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UserService} from './user.service';
import 'rxjs/add/operator/map';
import {User} from '../shared/models/user';
import {throwError} from 'rxjs';
import { Storage } from '@ionic/storage';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Router} from '@angular/router';
import {ToastController} from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    token = null;
  constructor(private http: HttpClient, private userService: UserService
  , private jwtHelper: JwtHelperService,
              private router: Router,
              public toastController: ToastController,
              public storage: Storage) {
      storage.get('token').then((token) => {
          this.token = token;
          if (this.token) {
              if (!this.jwtHelper.isTokenExpired(token)) {

                  const decodedUser = this.decodeUserFromToken(token);
                  this.setCurrentUser(decodedUser);
              } else {
                  this.logout();
              }

          }
      });




  }
  isLoggedIn = false;
    isAdmin = false;

    currentUser: User = new User();

  setLoggedIn(_value) {
    this.isLoggedIn = _value;
  }
    decodeUserFromToken(token) {
        return this.jwtHelper.decodeToken(token);
    }

    getAuthToken() {
        return 'Bearer ' + this.token;
    }

    login(emailAndPassword) {
      if (emailAndPassword.rememberme) {
          const credentials = {username: emailAndPassword.username, password: emailAndPassword.password};
          this.storage.set('credentials', credentials);
      } else {
          this.storage.remove('credentials');
      }
        this.storage.set('rememberme', emailAndPassword.rememberme);
        return this.userService.login(emailAndPassword).map(
            res => {
                if (res.error === true) {

                    this.toastController.create({
                        color: 'danger',
                        duration: 2000,
                        message: res.message,
                        showCloseButton: true
                    }).then(toast => {
                        toast.present();
                    });
                } else {
                    this.token = res.token;

                    this.storage.set('token', res.token).then((value) => {
                        const decodedUser = this.decodeUserFromToken(res.token);
                        this.setCurrentUser(decodedUser);
                        return this.isAuthenticated();
                    });

                }

            }
        );
    }

    setCurrentUser(decodedUser) {

        this.isLoggedIn = true;
        this.currentUser.uid = decodedUser.uid;
        this.currentUser.name = decodedUser.name;
        this.currentUser.email = decodedUser.email;
        this.currentUser.office = decodedUser.office;
        this.currentUser.role = decodedUser.role;
        decodedUser.role === '1' ? this.isAdmin = true : this.isAdmin = false;
        delete decodedUser.role;
        this.router.navigate(['/home']);
    }

    isAuthenticated(): boolean {

        if (this.token) {
                return !this.jwtHelper.isTokenExpired(this.token);
        } else {
            return false;
        }
    }

    isRememberMe(): any {
      let returnVal = false;
        this.storage.get('rememberme').then((rememberme) => {

            if (rememberme) {
                returnVal = true;
            } else {
                returnVal = false;
            }
            return  returnVal;
        });

    }

    logout() {
        this.storage.remove('token').then((value) => {
            this.token = null;
            this.isLoggedIn = false;
            this.isAdmin = false;
            this.currentUser = new User();
            this.router.navigate(['/login']);
        });

    }
}
