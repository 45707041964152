import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/authGuard.service';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', loadChildren: './public/login/login.module#LoginPageModule' },
  // { path: 'search', loadChildren: './search/search.module#SearchPageModule' ,  canActivate: [AuthGuardService]},
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' ,  canActivate: [AuthGuardService]},
  { path: 'home', loadChildren: './home/home.module#HomePageModule'  ,  canActivate: [AuthGuardService]},
  { path: 'clients', loadChildren: './clients/tabs/tabs.module#TabsPageModule',  canActivate: [AuthGuardService] },
  { path: 'clientinformation', loadChildren: './clients/clientinformation/clientinformation.module#ClientinformationPageModule' },
  { path: 'call-logs', loadChildren: './call-logs/call-logs.module#CallLogsPageModule' ,  canActivate: [AuthGuardService]},
  { path: 'add-new-client', loadChildren: './add-new-client/add-new-client.module#AddNewClientPageModule' ,  canActivate: [AuthGuardService]},
  { path: 'lookup-property-code', loadChildren: './lookup-property-code/lookup-property-code.module#LookupPropertyCodePageModule',  canActivate: [AuthGuardService] },
  { path: 'library', loadChildren: './library/library.module#LibraryPageModule' },
  { path: 'pending-requests', loadChildren: './pending-requests/pending-requests.module#PendingRequestsPageModule' },
  { path: 'company-emails', loadChildren: './company-emails/company-emails.module#CompanyEmailsPageModule' },
  { path: 'send-viewing-information', loadChildren: './send-viewing-information/send-viewing-information.module#SendViewingInformationPageModule' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
