import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientSearchService {

    constructor(private http: HttpClient) { }


    searchClients(request): Observable<any> {
        return this.http.post('/protected/searchMyClientsList', request);
    }

    getLocationsAndLink(request): Observable<any> {
        return this.http.post('/protected/getLocationsAndLink', request);
    }

    getLocations(): Observable<any> {
        return this.http.get('/protected/getLocations');
    }
}
