import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import {IonicModule, NavParams} from '@ionic/angular';

import { ClientinformationPage } from './clientinformation.page';

const routes: Routes = [
  {
    path: '',
    component: ClientinformationPage
  }
];

@NgModule({

  imports: [
    CommonModule,
    FormsModule,
    IonicModule
  ],
  declarations: [ClientinformationPage],
  exports: [ClientinformationPage],
  entryComponents: [ ClientinformationPage   ]
})
export class ClientinformationPageModule {}
