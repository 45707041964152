import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {AuthenticationService} from './authentication.service';
import { LoadingController } from '@ionic/angular';
import {catchError, map} from 'rxjs/operators';
import {LoadingService} from './loading.service';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
    loaderToShow: any;
    private apiReq: HttpRequest<any>;

    constructor(private authService: AuthenticationService, public loading: LoadingService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const authHeader = this.authService.getAuthToken();
        // const apiReq = req.clone({ url: `https://foxadministration.com/api/v2/public${req.url}`,
        this.loading.present();
        if (req.url.includes('updateRequestCondition')) {
            this.apiReq = req.clone({ url: `https://foxadministration.com/api/v1${req.url}`,
             // this.apiReq = req.clone({ url: `http://192.168.0.100/foxbackend/api/v1${req.url}`,

                headers: req.headers.set('Authorization', authHeader) });
        } else {

            this.apiReq = req.clone({ url: `https://foxadministration.com/api/v2/public${req.url}`,
            // this.apiReq = req.clone({ url: `http://192.168.0.100/foxbackend/api/v2/public${req.url}`,

                headers: req.headers.set('Authorization', authHeader) });
        }

        return next.handle( this.apiReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.loading.dismiss();
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                this.loading.dismiss();
                return throwError(error);
            }));
    }


}
