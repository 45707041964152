import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, RouteReuseStrategy, Routes } from '@angular/router';
import {IonicStorageModule, Storage} from '@ionic/storage';
import {IonicModule, IonicRouteStrategy, NavParams} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {APIInterceptor} from './services/apiinterceptor';
import { FormsModule } from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ClientinformationPageModule} from './clients/clientinformation/clientinformation.module';
import {InAppBrowser} from '@ionic-native/in-app-browser/ngx';

import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
import {EmailComposer} from '@ionic-native/email-composer/ngx';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';


export function jwtOptionsFactory(storage) {
    return {
        tokenGetter: () => {
            return storage.get('token').then((value) => {});
        },
        whitelistedDomains: ['localhost']
    };
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [    ],
  imports: [
    BrowserModule,
    FormsModule,
      ClientinformationPageModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
      FontAwesomeModule,

      JwtModule.forRoot({
          jwtOptionsProvider: {
              provide: JWT_OPTIONS,
              useFactory: jwtOptionsFactory,
              deps: [Storage]
          }
      })
  ],
  providers: [
    StatusBar,
    SplashScreen,
      InAppBrowser,
      LocalNotifications,
      EmailComposer,
      { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
      {
          provide: HTTP_INTERCEPTORS,
          useClass: APIInterceptor,
          multi: true,
      }

  ],
    exports: [FormsModule],
  bootstrap: [AppComponent]
})
export class AppModule {}
