import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ClientSearchService} from '../../services/client-search.service';

@Component({
  selector: 'app-clientinformation',
  templateUrl: './clientinformation.page.html',
  styleUrls: ['./clientinformation.page.css'],
})
export class ClientinformationPage implements OnInit {
client: {
    request: {};
};
  constructor( public modalController: ModalController, public clientService: ClientSearchService) {
  }

  ngOnInit() {
      this.clientService.getLocationsAndLink(this.client.request).subscribe(
          res =>  this.client.request = res
      );
  }

    dismiss() {
    this.modalController.dismiss();
    }

    openUrl(code) {
        window.open(code.url, '_system');
    }

}
