import { Component } from '@angular/core';

import {AlertController, NavController, Platform} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication.service';
import { Router } from '@angular/router';
import {User} from './shared/models/user';
import {Storage} from '@ionic/storage';
import {LocalNotifications} from '@ionic-native/local-notifications/ngx';
declare var PhoneCallTrap: any;
declare var window: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
    selectedPath = '';
    state = 'IDLE';



    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private auth: AuthenticationService,
        private router: Router,
        public storage: Storage,
        private alertCtrl: AlertController,
        private localNotifications: LocalNotifications,
        public navCtrl: NavController
    ) {
        this.initializeApp();
    }
    public isMD = this.platform.is('android');
    public isIOS = this.platform.is('ios');

    public appPages = [
        {
            title: 'Home',
            url: '/home',
            icon: 'home',
            show: true
        },
        {
            title: 'Profile',
            url: '/profile',
            icon: 'contact',
            show: true
        },
        {
            title: 'Search Clients',
            url: '/clients',
            icon: 'people',
            show: true
        },
        {
            title: 'Add client',
            url: '/add-new-client',
            icon: 'contacts',
            show: true
        },

        {
            title: 'Pending Enquiries',
            url: '/pending-requests',
            icon: 'checkmark',
            show: true
        },
        {
            title: 'Lookup property reference code',
            url: '/lookup-property-code',
            icon: 'search',
            show: true

        },

        // {
        //     title: 'Library',
        //     url: '/library',
        //     icon: 'book',
        //     show: false
        // },

        {
            title: 'Company Emails',
            url: '/company-emails',
            icon: 'mail',
            show: true
        },
        {
            title: 'Send viewing information',
            url: '/send-viewing-information',
            icon: 'information-circle',
            show: true
        }
    ];

    logout() {
        this.storage.remove('token').then((value) => {
        });
        this.auth.token = null;
        this.auth.isLoggedIn = false;
        this.auth.isAdmin = false;
        this.auth.currentUser = new User();
        this.router.navigate(['/login']);
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleLightContent();
            // setTimeout(() => {
            //     this.splashScreen.hide();
            //     if (this.platform.is('cordova')) {
            //         this.setupPush();
            //     }
            //     // this.checkPermissionCallLog();
            //     // this.checkPermissionCall();
            //     this.localNotifications.on('click').subscribe((not) => {
            //         if (not.text === 'Dont forget you can add a new client. Click to navigate to panel') {
            //             this.navCtrl.navigateForward(['call-logs']);
            //         }
            //         if (not.text === 'You can add a new client while talking. Click to navigate to panel') {
            //             this.navCtrl.navigateForward(['add-new-client']);
            //         }
            //
            //     });
            //     // this.router.navigate(['/clients']);
            // }, 1000);
        });
    }

    // phone calls
    checkPermissionCall() {
        // this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(
        //     success => {
        //         // if permission granted
        //         this.phonecalls();
        //     },
        //     err => {
        //         this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE).then(success => {
        //                 this.phonecalls();
        //             },
        //             // tslint:disable-next-line:no-shadowed-variable
        //             err => {
        //                 console.log('cancelled');
        //             });
        //     });
        // this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.READ_PHONE_STATE]);
    }
    async checkPermissionCallLog() {
        // try {
        //     const { hasPermission } = await this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.READ_CALL_LOG);
        //     if (!hasPermission) {
        //         const result = await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.READ_CALL_LOG);
        //
        //         if (!result.hasPermission) {
        //             console.log('call log permission is false');
        //         }
        //     } else {
        //         console.log('call log permission is true');
        //         console.log('calling loadCallLog()');
        //     }
        //
        // } catch (errorPermission) {
        //     console.error('Error in Call log', errorPermission);
        // }
    }

    // phone calls
    phonecalls() {
            PhoneCallTrap.onCall((obj) => {

                switch (obj) {
                    case 'RINGING':
                        this.state = 'RINGING';
                        break;
                    case 'OFFHOOK':
                        if ( this.state === 'RINGING') {
                            this.state = 'OFFHOOK';
                            this.localNotifications.schedule({
                                text: 'You can add a new client while talking. Click to navigate to panel',
                                trigger: {at: new Date(new Date().getTime() + 60)},
                                led: 'FF0000',
                                sound: null
                            });
                        }

                        break;

                    case 'IDLE':
                        if ( this.state === 'RINGING') {
                            this.state = 'IDLE';
                            this.localNotifications.schedule({
                                text: 'Dont forget you can add a new client. Click to navigate to panel',
                                trigger: {at: new Date(new Date().getTime() + 60)},
                                led: 'FF0000',
                                sound: null
                            });
                        }

                        break;
                }
            });


    }

    setupPush() {
        // I recommend to put these into your environment.ts
        // this.oneSignal.startInit('dacac613-e9a7-4301-bcb4-3a4edb67e456', 'foxapp-81437');
        //
        // this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None);
        //
        // // Notifcation was received in general
        // this.oneSignal.handleNotificationReceived().subscribe(data => {
        //     const msg = data.payload.body;
        //     const title = data.payload.title;
        // //    this.showAlert(title, msg);
        // });
        //
        // // Notification was really clicked/opened
        // this.oneSignal.handleNotificationOpened().subscribe(data => {
        //     // Just a note that the data is a different place here!
        //    if (data.notification.payload.title === 'Incoming Enquiry' || data.notification.payload.title === 'Pending Enquiry') {
        //        this.navCtrl.navigateForward(['pending-requests']);
        //    }
        //
        // });
        //
        // this.oneSignal.endInit();



    }



    async showAlert(title, msg) {
        const alert = await this.alertCtrl.create({
            header: title,
            subHeader: msg

        });
        alert.present();
    }

}
